import environments from '@app/configs/environments';

const globalConstants = {
    appName: 'Niraj Portfolio',
    appDesc: "Hi! I'm Niraj, an electronics engineer with a passion for coding. I've honed my programming skills through a blend of structured learning and self-teaching",
    title: 'Niraj Duwal',
    favIcon: 'favIcon',
    twitterHandle: '@kneerose8',
    titleImg: 'titleImg',
    socialPreview: {
        url: `https://www.duwalniraj.com.np/`,
        title: 'Niraj Duwal',
        desc: "Hi! I'm Niraj, an electronics engineer with a passion for coding. I've honed my programming skills through a blend of structured learning and self-teaching",
        image: `/images/thumbnail.jpg`
    },
    consoleWarningTitle: `%cStop!`,
    consoleWarningDescription: `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and will give them access to your sensitive information.`,
    imageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']
};

export default globalConstants;
